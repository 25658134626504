import React, { Suspense, lazy } from "react";

import routes from "./routes";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import UserLayout from "containers/Layout";
import Spinner from "components/Spinner";
import DashboardLayout from "containers/Dashboard/DashboardLayout";

const NotFound = lazy(() => import("containers/NotFound"));
const Index = lazy(() => import("containers/Home"));

const Dashboard = lazy(() => import("containers/Dashboard/Dashboard"));
const Supply = lazy(() => import("containers/Dashboard/Supply"));
const RebaseHistory = lazy(() => import("containers/Dashboard/RebaseHistory"));
const Oracles = lazy(() => import("containers/Dashboard/Oracles"));

const Loading = () => (
  <div
    style={{
      minHeight: 800,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    }}
  >
    <Spinner />
  </div>
);

function MainRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <UserLayout>
            <Route exact strict path="/">
              <Suspense fallback={<Loading />}>
                <Index />
              </Suspense>
            </Route>

            <Route path="/dashboard">
              <DashboardLayout>
                <Route exact strict path="/dashboard">
                  <Suspense fallback={<Loading />}>
                    <Dashboard />
                  </Suspense>
                </Route>

                <Route exact strict path="/dashboard/supply">
                  <Suspense fallback={<Loading />}>
                    <Supply />
                  </Suspense>
                </Route>

                <Route exact strict path="/dashboard/rebase-history">
                  <Suspense fallback={<Loading />}>
                    <RebaseHistory />
                  </Suspense>
                </Route>
                <Route exact strict path="/dashboard/oracles">
                  <Suspense fallback={<Loading />}>
                    <Oracles />
                  </Suspense>
                </Route>
              </DashboardLayout>
            </Route>

            {routes.map((route, i) => {
              if (!route.protected) {
                return (
                  <Route key={i} exact strict path={route.path}>
                    <Suspense fallback={<Loading />}>
                      <route.component params={route.params} />
                    </Suspense>
                  </Route>
                );
              } else {
                return (
                  <ProtectedRoute key={i} exact strict path={route.path}>
                    <Suspense fallback={<Loading />}>
                      <route.component params={route.params} />
                    </Suspense>
                  </ProtectedRoute>
                );
              }
            })}
          </UserLayout>
        </Route>

        <Route exact strict path="*">
          <Suspense fallback={<Loading />}>
            <NotFound />
          </Suspense>
        </Route>
      </Switch>
    </Router>
  );
}

export default MainRouter;
