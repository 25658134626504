import { useMemo, forwardRef } from "react";
import clsx from "clsx";
import {
  ListItem,
  ListItemText,
  makeStyles,
  ListItemIcon,
  SvgIcon,
} from "@material-ui/core";
import { NavLink as RouterLink, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  active: {
    // borderBottomWidth: 1,
    // borderBottomStyle: "solid",
    borderBottomColor: "#ff2d55",
    fontWeight: 900,
    color: "#ff2d55",
  },
  navItem: {
    color: "#000",
    textTransform: "uppercase",
    // width: "auto",
    "&:hover": {
      color: "#ff2d55",
    },
    padding: theme.spacing(3),
  },
  navText: {
    fontSize: 12,
  },
  desc: {
    fontSize: 10,
    color: "red",
  },
}));

export default function ListItemLink({
  to,
  svgIc,
  description,
  title,
  ...rest
}) {
  const classes = useStyles();

  const match = useRouteMatch(to);

  const Link = useMemo(
    () =>
      forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem
      component={Link}
      classes={{
        root: classes.navItem,
      }}
      underline="none"
      {...rest}
    >
      <ListItemIcon className={classes.iconStart}>
        {svgIc ? (
          <SvgIcon
            component={svgIc.svg}
            viewBox={svgIc.viewBox}
            className={classes.svgStart}
          />
        ) : null}
      </ListItemIcon>

      <ListItemText
        classes={{
          primary: match?.isExact
            ? clsx(classes.active, classes.navText)
            : classes.navText,
          secondary: classes.desc,
        }}
        primary={title}
        secondary={description}
      />
    </ListItem>
  );
}
