import React, { useState, useMemo, useContext } from "react";

export const ModalContext = React.createContext();

export const useModalStore = () => {
  const web3store = useContext(ModalContext);
  return web3store;
};

export default function ModalProvider({ children }) {
  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const [openLinkShinobi, setOpenLinkShinobi] = useState(false);

  const value = useMemo(
    () => ({
      walletModalVisible,
      setWalletModalVisible,
      openLinkShinobi,
      setOpenLinkShinobi,
    }),
    [
      walletModalVisible,
      setWalletModalVisible,
      openLinkShinobi,
      setOpenLinkShinobi,
    ]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}
