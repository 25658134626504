import { Fragment } from "react";
import { useWallet } from "use-wallet";

import Dialog, { DialogTitle } from "components/DialogStyled";
import {
  Typography,
  makeStyles,
  Paper,
  Grid,
  useTheme,
  useMediaQuery,
  Link,
} from "@material-ui/core";

import { TRANSACTION_STATUS } from "common/constants";

import { useTransactionStore } from "store/TransactionProvider";

import { getCookie } from "common/cookies";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PageviewIcon from "@material-ui/icons/Pageview";

import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { formatAddress } from "common/utils";
import { useTranslation } from "react-i18next";

function AccountDetails({ open, onClose }) {
  const { t } = useTranslation();
  const wallet = useWallet();
  const { transactions } = useTransactionStore();
  const classes = useStyles();

  const connector = getCookie("connector");

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        open={open}
        PaperProps={{ classes: { root: classes.paper } }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle onClose={onClose}>{t("account")}</DialogTitle>

        <Paper variant="outlined" className={classes.paperContent}>
          <Typography variant="body1" color="textSecondary">
            {t("account_details.connected_with")} {connector}
          </Typography>
          <Typography variant="h6">
            <AccountBalanceWalletIcon color="secondary" />{" "}
            {matches ? formatAddress(wallet?.account) : wallet?.account}
          </Typography>
          <Typography variant="overline" color="secondary">
            {t("balances")}:{" "}
            {matches ? formatAddress(wallet?.account) : wallet?.balance}
            {" ETH"}
          </Typography>
          <Grid container spacing={3}>
            <Grid item>
              <CopyToClipboard text={wallet?.account}>
                <Typography variant="caption" className={classes.btn}>
                  <FileCopyIcon
                    style={{
                      fontSize: 14,
                    }}
                  />{" "}
                  {t("account_details.copy_address")}
                </Typography>
              </CopyToClipboard>
            </Grid>
            <Grid item>
              <Typography variant="caption" className={classes.btn}>
                <PageviewIcon
                  style={{
                    fontSize: 14,
                  }}
                />{" "}
                <Link
                  href={`https://etherscan.io/address/${wallet?.account}`}
                  target="_blank"
                >
                  {t("view_in_etherscan")}{" "}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Typography variant="body1" color="secondary" style={{ marginTop: 8 }}>
          {t("account_details.recent_trans")}
        </Typography>
        <Paper variant="outlined" classes={{ root: classes.tx }}>
          {transactions.length > 0 &&
            transactions.map((el, index) => (
              <Paper
                classes={{
                  root: classes.paperItem,
                }}
                key={index}
              >
                <Grid container>
                  <Grid item container>
                    <Grid item xs>
                      <Typography variant="body1" color="secondary">
                        {el.description}
                      </Typography>
                    </Grid>

                    <Grid item>
                      {el.status === TRANSACTION_STATUS.PENDING && (
                        <AllInclusiveIcon style={{ color: "#00e676" }} />
                      )}

                      {el.status === TRANSACTION_STATUS.SUCCESS && (
                        <CheckCircleIcon color="secondary" />
                      )}

                      {el.status === TRANSACTION_STATUS.ERROR && (
                        <WarningIcon color="error" />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography
                    variant="overline"
                    color="textSecondary"
                    style={{ fontStyle: "italic" }}
                    className={classes.btn}
                    onClick={() =>
                      window.open(
                        `https://ropsten.etherscan.io/tx/${el.transactionHash}`
                      )
                    }
                  >
                    <PageviewIcon
                      style={{
                        fontSize: 18,
                      }}
                    />{" "}
                    Tx hash: {formatAddress(el.transactionHash)}
                  </Typography>
                </Grid>
              </Paper>
            ))}

          {transactions.length === 0 && (
            <Typography variant="caption">
              {t("account_details.no_recent_trans")}
            </Typography>
          )}
        </Paper>
      </Dialog>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  border: {},
  paper: {
    borderRadius: 15,
    padding: theme.spacing(2),
  },
  paperContent: {
    borderRadius: 15,
    padding: theme.spacing(2),
  },
  btn: {
    cursor: "pointer",
  },
  tx: {
    padding: theme.spacing(1),
    maxHeight: 200,
    overflow: "auto",
    backgroundColor: theme.palette.background.default,
  },
  paperItem: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    padding: 8,
    borderRadius: 10,
    margin: "16px 0",
  },
}));

export default AccountDetails;
