import Cookies from "js-cookie";

export const setCookie = (key, value) => {
  try {
    Cookies.set(key, value);
  } catch (error) {
    console.log(error);
  }
};

export const getCookie = (key) => {
  try {
    const value = Cookies.get(key);
    return value;
  } catch (error) {
    console.log(error);
  }
};

export const removeCookie = (key) => {
  try {
    Cookies.remove(key);
  } catch (error) {
    console.log(error);
  }
};
