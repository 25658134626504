import { useState, useEffect, useRef } from "react";
import { useModalStore } from "store/ModalProvider";
import { useWallet } from "use-wallet";

import { Grid, Button, makeStyles, Typography } from "@material-ui/core";
import WalletModal from "components/WalletModal";
import { CONNECT_STATUS } from "common/constants";
import { formatAddress } from "common/utils";
import { removeCookie } from "common/cookies";
import { Fragment } from "react";

import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Spinner from "./Spinner";
import AccountDetails from "./AccountDetails";
import { useTranslation } from "react-i18next";
import { Hidden } from "@material-ui/core";
// import Web3 from "web3";
// import { useWeb3 } from "contracts";

function Account() {
  const { setWalletModalVisible } = useModalStore();
  const wallet = useWallet();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [accDetails, setAccDetails] = useState(false);
  const anchorRef = useRef(null);

  const { t } = useTranslation();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  // useEffect(() => {
  //   if (wallet && !wallet.ethereum) {
  //     wallet.reset();
  //   }
  // }, [wallet]);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const onDisconnect = () => {
    removeCookie("connector");
    wallet.reset();
    localStorage.removeItem("walletconnect");
  };
  // useEffect(() => {
  //   if (!wallet || wallet.status === CONNECT_STATUS.DISCONNECTED) {
  //     localStorage.removeItem("walletconnect");
  //     removeCookie("connector");
  //     wallet.reset();
  //   }
  // }, [wallet]);

  return (
    <Fragment>
      {wallet.status === CONNECT_STATUS.CONNECTING ? (
        <Spinner width={32} height={32} style={{ overflow: "inherit" }} />
      ) : wallet.status === CONNECT_STATUS.CONNECTED ? (
        <div className={classes.root}>
          <Button
            disableRipple
            edge="start"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <AccountCircleSharpIcon color="secondary" fontSize="large" />
            <KeyboardArrowDownIcon color="secondary" />
          </Button>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper variant="outlined">
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={() => setAccDetails(true)}>
                        <Grid container>
                          <Hidden xsDown>
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center"
                              md={3}
                            >
                              <AccountBalanceWalletIcon color="secondary" />
                            </Grid>
                          </Hidden>
                          <Grid item md={8} container>
                            <Grid item xs>
                              <Typography variant="subtitle1">
                                {t("account")}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                className={classes.account}
                              >
                                {formatAddress(wallet.account)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          container
                          justify="center"
                          alignItems="center"
                          md={1}
                        >
                          <KeyboardArrowRightIcon color="secondary" />
                        </Grid>
                      </MenuItem>

                      <MenuItem onClick={onDisconnect}>
                        <Grid container>
                          <Hidden xsDown>
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center"
                              md={3}
                            >
                              <ExitToAppIcon color="secondary" />
                            </Grid>
                          </Hidden>
                          <Grid item md={9} container>
                            <Typography variant="subtitle1">
                              {t("disconnect")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      ) : (
        <button
          className="btn btn-red align-self-center"
          onClick={() => {
            wallet.reset();
            setWalletModalVisible(true);
          }}
        >
          {t("connect_wallet")}
        </button>
      )}
      <WalletModal />
      <AccountDetails open={accDetails} onClose={() => setAccDetails(false)} />
    </Fragment>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  account: {
    fontStyle: "italic",
  },
}));

export default Account;
