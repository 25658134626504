import { makeStyles, Drawer, Divider, List } from "@material-ui/core";
import { THEME_SETTING } from "common/constants";
import SidebarItem from "./components/SidebarItem";

import { ReactComponent as dashboard } from "assets/images/svg/dashboard.svg";
import { ReactComponent as supply } from "assets/images/svg/supply.svg";
import { ReactComponent as history } from "assets/images/svg/history.svg";
import { ReactComponent as oracles } from "assets/images/svg/oracles.svg";
import { useTranslation } from "react-i18next";

function Index({ children }) {
  const { t } = useTranslation();

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List>
          <SidebarItem
            to="/dashboard"
            svgIc={{
              svg: dashboard,
              viewBox: "0 0 512.141 512.141",
            }}
            title={t("dashboard.dashboard")}
          />
          <Divider />

          <SidebarItem
            to="/dashboard/supply"
            svgIc={{
              svg: supply,
              viewBox: "0 0 512 512",
            }}
            title={t("dashboard.supply")}
          />

          <Divider />

          <SidebarItem
            to="/dashboard/rebase-history"
            svgIc={{
              svg: history,
              viewBox: "0 0 512.089 512.089",
            }}
            title={t("dashboard.rebase_history")}
          />
          <Divider />

          <SidebarItem
            to="/dashboard/oracles"
            svgIc={{
              svg: oracles,
              viewBox: "0 0 128 128",
            }}
            title={t("dashboard.oracles")}
            description={t("dashboard.coming_soon")}
          />
          <Divider />
        </List>
      </Drawer>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: THEME_SETTING.APP_BAR_HEIGHT + theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingTop: THEME_SETTING.APP_BAR_HEIGHT,
    },
  },
  drawer: {
    width: 300,
    flexShrink: 0,
    [theme.breakpoints.down("md")]: {
      width: 80,
    },
  },
  drawerPaper: {
    width: 300,
    paddingTop: THEME_SETTING.APP_BAR_HEIGHT + theme.spacing(2),
    zIndex: 100,
    [theme.breakpoints.down("md")]: {
      width: 80,
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    marginLeft: 300,
    zIndex: 99,
    minHeight: 800,
    [theme.breakpoints.down("md")]: {
      marginLeft: 80,
    },
  },
}));

export default Index;
