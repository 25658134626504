import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

import MainRouter from "routes/MainRouter";
import ModalProvider from "store/ModalProvider";
import ReactNotification from "react-notifications-component";
import "common/i18n";

import { UseWalletProvider } from "use-wallet";
import { CHAIN_ID, CONNECTORS } from "common/constants";
import TransactionProvider from "store/TransactionProvider";
import { useTranslation } from "react-i18next";
import { languagesLabel } from "common/i18n";

//-------------------------------------

let theme = createMuiTheme({
  typography: {
    subtitle1: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 500,
    },
    h2: {
      fontSize: "18px !important",
      lineHeight: "25px",
      fontWeight: 500,
    },
    fontFamily: ["Asap", "sans-serif"].join(", "),
  },
});
// theme = responsiveFontSizes(theme);

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const trans = languagesLabel.map((el) => el.code);
    if (trans.findIndex((el) => el === i18n.language) === -1) {
      i18n.changeLanguage("en-US");
    }
  }, [i18n]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ReactNotification />
      <UseWalletProvider connectors={CONNECTORS}>
        <TransactionProvider>
          <ModalProvider>
            <MainRouter />
          </ModalProvider>
        </TransactionProvider>
      </UseWalletProvider>
    </ThemeProvider>
  );
};

export default App;
