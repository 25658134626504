import MetamaskIcon from "assets/images/wallet/metamask.svg";

import WalletconnectIcon from "assets/images/wallet/walletConnectIcon.svg";
import ShinobiWalletIcon from "assets/images/shinobi.png";

export const MOCK = {
  dataSource: [
    {
      key: "1",
      name: "John Brown",
      price: 0.000067,
      rate: 0.015,
    },
    {
      key: "2",
      name: "Jim Green",
      price: 0.000032,
      rate: -1,
    },
    {
      key: "3",
      name: "Joe Black",
      price: 0.000012,
      rate: 2,
    },
    {
      key: "4",
      name: "John Brown",
      price: 0.000036,
      rate: 2.56,
    },
    {
      key: "5",
      name: "Jim Green",
      price: 0.0000023,
      rate: -1.3,
    },
    {
      key: "6",
      name: "Joe Black",
      price: 0.00056,
      rate: 4,
    },
    {
      key: "7",
      name: "John Brown",
      price: 0.0000045,
      rate: -1.5,
    },
    {
      key: "8",
      name: "Jim Green",
      price: 0.0000035,
      rate: 2,
    },
    {
      key: "9",
      name: "Joe Black",
      price: 0.000045,
      rate: 3,
    },
    {
      key: "1" + 1,
      name: "John Brown",
      price: 0.000067,
      rate: 0.015,
    },
    {
      key: "2" + 1,
      name: "Jim Green",
      price: 0.000032,
      rate: -1,
    },
    {
      key: "3" + 1,
      name: "Joe Black",
      price: 0.000012,
      rate: 2,
    },
    {
      key: "4" + 1,
      name: "John Brown",
      price: 0.000036,
      rate: 2.56,
    },
    {
      key: "5" + 1,
      name: "Jim Green",
      price: 0.0000023,
      rate: -1.3,
    },
    {
      key: "6" + 1,
      name: "Joe Black",
      price: 0.00056,
      rate: 4,
    },
    {
      key: "7" + 1,
      name: "John Brown",
      price: 0.0000045,
      rate: -1.5,
    },
    {
      key: "8" + 1,
      name: "Jim Green",
      price: 0.0000035,
      rate: 2,
    },
    {
      key: "9" + 1,
      name: "Joe Black",
      price: 0.000045,
      rate: 3,
    },
  ],
};

export const THEME_SETTING = {
  APP_BAR_HEIGHT: 90,
};

export const CONNECTORS = {
  injected: {
    chainId: [1],
  },
  walletconnect: {
    rpcUrl: process.env.REACT_APP_RPC_URL,
    chainId: 1,
  },
  portis: { dAppId: "my-dapp-id-123-xyz", chainId: [1] },
};

export const CONNECT_STATUS = {
  DISCONNECTED: "disconnected",
  CONNECTING: "connecting",
  CONNECTED: "connected",
  ERROR: "error",
};

export const WALLETS = {
  metamask: {
    name: "MetaMask",
    image: MetamaskIcon,
    description: "Easy-to-use browser extension.",
  },
  shinobiwallet: {
    name: "Shinobi Wallet",
    image: ShinobiWalletIcon,
    description: "Connect to Shinobi Wallet",
  },
  walletconnect: {
    name: "Wallet Connect",
    image: WalletconnectIcon,
    description: "Connect to Trust Wallet, Rainbow Wallet and more...",
  },
};

export const ACTION = {
  BUY: 0,
  SELL: 1,
};

export const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID) || 1;

export const TRANSACTION_STATUS = {
  PENDING: 0,
  SUCCESS: 1,
  ERROR: 2,
};

export const COEFFICIENT_GAS_PRICE = 2;

export const MAX_APPROVE_KGR = "10000000000000000000000000"; // 10000000 KGR

export const MAX_LENGTH_FLOAT = 5;
