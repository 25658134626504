import { lazy } from "react";
//-------------------------------------
const Exchange = lazy(() => import("containers/Exchange"));

const Stake = lazy(() => import("containers/Stake"));

const Work = lazy(() => import("containers/Learn/Work"));

const Technology = lazy(() => import("containers/Learn/Technology"));

const OmkWork = lazy(() => import("containers/Learn/OmkWork"));

const routes = [
  {
    path: "/exchange",
    component: Exchange,
  },
  {
    path: "/stake",
    component: Stake,
  },
  {
    path: "/basics",
    component: Work,
  },
  {
    path: "/technology",
    component: Technology,
  },
  {
    path: "/how-OMK-work",
    component: OmkWork,
  },
];

export default routes;
