import BN from "bn.js";
import * as echarts from "echarts";
import { COEFFICIENT_GAS_PRICE, MAX_APPROVE_KGR } from "./constants";

export const resizeObserver = new ResizeObserver((entries) => {
  // eslint-disable-next-line
  entries.map(({ target }) => {
    const instance = echarts.getInstanceByDom(target);
    if (instance) {
      instance.resize();
    }
  });
});

export const isValidAddress = (address) => {
  return /^(0x)?[0-9a-f]{40}$/i.test(address);
};

export const formatCurrency = (source, amount = 6) => {
  if (!source) return 0;
  return Number.parseFloat(source).toFixed(amount);
  // return [
  //   str.toString().split('.')[0],
  //   str.toString().split('.')[1].slice(0, amount),
  // ].join('.');
};

export const formatAddress = (address) => {
  if (address) return address.slice(0, 20) + "..." + address.slice(-4);
};

export const formatDuration = (seconds) => {
  var h = Math.floor(seconds / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor((seconds % 3600) % 60);

  var hDisplay = h >= 0 ? h + "h:" : "";
  var mDisplay = m >= 0 ? m + "m:" : "";
  var sDisplay = s >= 0 ? s + "s" : "";
  return hDisplay + mDisplay + sDisplay;
};

export const nFormatter = (num, digits) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const getGasPrice = async (web3) => {
  try {
    const curGasPrice = await web3.eth.getGasPrice();
    // console.log(123);
    const gasPrice = (curGasPrice * COEFFICIENT_GAS_PRICE)
      .toString()
      .split(".")[0];
    return gasPrice;
  } catch (error) {
    console.log(error);
  }
};

export const isEnoughETH = async (web3, account, eth) => {
  try {
    const ethBalance = await web3.eth.getBalance(account);
    console.log(ethBalance);
    const ethBalanceBN = new BN(ethBalance);

    const ethAmountBN = new BN(eth);

    if (ethBalanceBN.gte(ethAmountBN)) return true;
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const isEnoughKGR = async (kgrContract, account, kgr) => {
  try {
    const kgrBalance = await kgrContract.methods.balanceOf(account).call();

    console.log(kgrBalance);
    const kgrBalanceBN = new BN(kgrBalance);

    const kgrAmountBN = new BN(kgr);

    if (kgrBalanceBN.gte(kgrAmountBN)) return true;
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const isAllowance = async (kgrContract, owner, spender) => {
  try {
    const allowance = await kgrContract.methods
      .allowance(owner, spender)
      .call();
    console.log("allowance", allowance);

    const allowanceBN = new BN(allowance);
    const maxApproveBN = new BN(MAX_APPROVE_KGR);

    if (allowanceBN.gte(maxApproveBN)) return true;
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export function formatRate(number) {
  return Number(number).toLocaleString("en-US", { minimumFractionDigits: 2 });
}

export const isMobile = function () {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};
