import { TRANSACTION_STATUS } from "common/constants";
import { formatAddress } from "common/utils";
import React, { useState, useMemo, useContext } from "react";
import { store } from "react-notifications-component";
export const TransactionContext = React.createContext();

export const useTransactionStore = () => {
  const web3store = useContext(TransactionContext);
  return web3store;
};

export default function TransactionProvider({ children }) {
  const [transactions, setTransactions] = useState([]);

  const updateTransaction = (txHash, status) => {
    const transaction = transactions.find(
      (transaction) =>
        transaction.transactionHash.toLowerCase() === txHash.toLowerCase()
    );
    if (transaction) {
      transaction.status = status;
      setTransactions([...transactions]);
    }
  };

  const send = ({
    method,
    description,
    options,
    callbackAfterCall,
    callbackAfterDone,
    callbackAfterError,
  }) => {
    method
      .estimateGas(options)
      .then(function (gasAmount) {
        // console.log({ gasAmount: gasAmount, options: options });
        method
          .send({ ...options, gas: gasAmount })
          .on("transactionHash", function (hash) {
            console.log(hash);
            const transaction = {
              transactionHash: hash,
              description,
              status: TRANSACTION_STATUS.PENDING,
            };

            transactions.push(transaction);
            setTransactions([...transactions]);

            store.addNotification({
              title: "Create transaction success",
              message: "Tx hash: " + formatAddress(hash),
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });

            if (callbackAfterCall && typeof callbackAfterCall === "function")
              callbackAfterCall();
          })
          .then(function (receipt) {
            if (receipt?.transactionHash) {
              updateTransaction(
                receipt.transactionHash,
                TRANSACTION_STATUS.SUCCESS
              );
            }

            store.addNotification({
              title: description,
              message: "Tx hash: " + formatAddress(receipt?.transactionHash),
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });

            if (callbackAfterDone && typeof callbackAfterDone === "function")
              callbackAfterDone();
          })
          .catch(function (error, receipt) {
            console.log(error);
            if (receipt?.transactionHash) {
              updateTransaction(
                receipt.transactionHash,
                TRANSACTION_STATUS.ERROR
              );
            }

            store.addNotification({
              title: description,
              message: "Rejected Transaction!",
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });

            if (callbackAfterError && typeof callbackAfterError === "function")
              callbackAfterError();
          });
      })
      .catch(function (error) {
        console.log("error", error);
        store.addNotification({
          title: description,
          message: error.message.slice(20, 72) + "...",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        if (callbackAfterError && typeof callbackAfterError === "function")
          callbackAfterError();
      });
  };

  const value = useMemo(
    () => ({
      transactions,
      setTransactions,
      updateTransaction,
      send,
    }),
    // eslint-disable-next-line
    [transactions, setTransactions]
  );

  return (
    <TransactionContext.Provider value={value}>
      {children}
    </TransactionContext.Provider>
  );
}
