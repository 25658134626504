import { Fragment, useState, useEffect } from "react";
import {
  ChainUnsupportedError,
  ConnectionRejectedError,
  useWallet,
} from "use-wallet";
import { useModalStore } from "store/ModalProvider";
import { getCookie, setCookie } from "common/cookies";

import ButtonImage from "components/ButtonImage";
import Spinner from "components/Spinner";
import Dialog, { DialogTitle, DialogContent } from "components/DialogStyled";
import { Button, Typography, makeStyles, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { CONNECT_STATUS, WALLETS } from "common/constants";
import { useTranslation } from "react-i18next";
import { isMobile } from "common/utils";

function WalletModal() {
  const { connector, walletConnectUri, ...wallet } = useWallet();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    walletModalVisible,
    setWalletModalVisible,
    openLinkShinobi,
    setOpenLinkShinobi,
  } = useModalStore();
  const [errorMessage, setErrorMessage] = useState("");

  const onConnect = (connector) => {
    if (connector === "metamask") {
      wallet.connect();
    } else if (connector === "shinobiwallet" && !isMobile()) {
      wallet.connect("walletconnect");
    } else {
      wallet.connect(connector);
    }
    setCookie("connector", connector);
  };

  const onClose = () => {
    setWalletModalVisible(false);
  };
  useEffect(() => {
    if (
      walletConnectUri &&
      connector === "shinobiwallet" &&
      !openLinkShinobi &&
      wallet.status !== CONNECT_STATUS.CONNECTED
    ) {
      window.location.href = `shinobi-wallet://walletConnect/${walletConnectUri}`;
    }
  }, [
    connector,
    walletConnectUri,
    openLinkShinobi,
    setOpenLinkShinobi,
    wallet.status,
  ]);

  // useEffect(() => {
  //   const connector = getCookie("connector");
  //   if (connector) onConnect(connector);

  //   //eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (wallet.status === CONNECT_STATUS.CONNECTED) {
      setWalletModalVisible(false);
    } else if (wallet.status === CONNECT_STATUS.ERROR) {
      if (wallet.error instanceof ChainUnsupportedError) {
        setErrorMessage(t("connect_notify.network_not_support"));
      } else if (wallet.error instanceof ConnectionRejectedError) {
        setErrorMessage(t("connect_notify.user_reject"));
      } else {
        setErrorMessage(wallet?.error?.name || "");
      }
    }
  }, [wallet, setWalletModalVisible, t]);
  useEffect(() => {
    if (wallet && wallet.status === CONNECT_STATUS.CONNECTED) {
    } else {
      setOpenLinkShinobi(false);
    }
  }, [setOpenLinkShinobi, wallet]);

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        open={walletModalVisible}
        fullWidth
        maxWidth="sm"
      >
        {wallet.status === CONNECT_STATUS.DISCONNECTED ? (
          <Fragment>
            <DialogTitle onClose={onClose}>{t("connect_wallet")}</DialogTitle>

            <DialogContent dividers>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                {Object.keys(WALLETS).map((connector) => (
                  <ButtonImage
                    key={connector}
                    image={WALLETS[connector].image}
                    label={WALLETS[connector].name}
                    width="30%"
                    imageStyle={{
                      width: "80%",
                    }}
                    onClick={() => onConnect(connector)}
                  />
                ))}
              </div>
            </DialogContent>
          </Fragment>
        ) : (
          <Fragment>
            <DialogTitle onClose={onClose}>
              <IconButton
                variant="outlined"
                color="primary"
                onClick={() => wallet.reset()}
              >
                <ArrowBackIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent dividers>
              <div className={classes.border} style={{ marginBottom: "36px" }}>
                {wallet.status === CONNECT_STATUS.CONNECTING && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Spinner width="64px" height="64px" />
                    <Typography variant="subtitle1" color="textSecondary">
                      {t("connect_notify.connecting")}
                    </Typography>
                  </div>
                )}
                {wallet.status === CONNECT_STATUS.ERROR && (
                  <Fragment>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography component="h5" variant="h5">
                        {t("connect_error")}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        {errorMessage}
                      </Typography>
                    </div>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        wallet.reset();
                        onConnect(connector);
                      }}
                    >
                      {t("connect_notify.try_again")}
                    </Button>
                  </Fragment>
                )}
              </div>
              <div className={classes.border}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography component="h5" variant="h5">
                    {WALLETS[connector]?.name || ""}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {WALLETS[connector]?.description || ""}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ width: "64px" }}
                    src={WALLETS[connector]?.image}
                    alt=""
                  />
                </div>
              </div>
            </DialogContent>
          </Fragment>
        )}
      </Dialog>
    </Fragment>
  );
}

const useStyles = makeStyles(() => ({
  border: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderStyle: "solid",
    borderRadius: "16px",
    borderWidth: "1px",
    borderColor: "#ff2d55",
    padding: "10px",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
}));

export default WalletModal;
