import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "i18n/en_US.json";
// import vi from "i18n/vi_VN.json";
import ja from "i18n/ja_JP.json";

import { ReactComponent as engFlag } from "assets/images/svg/usa.svg";
// import viFlag from 'assets/images/svg/nation.svg';
import { ReactComponent as jaFlag } from "assets/images/svg/japan.svg";
//-------------------------------------

export const languagesLabel = [
  {
    code: "en-US",
    text: "English",
    flag: {
      svg: engFlag,
      viewBox: "0 0 512 512",
    },
  },
  // {
  //   code: 'vi-VN',
  //   text: 'Tiếng Việt',
  //   flag: viFlag,
  // },
  {
    code: "ja-JP",
    text: "日本語",
    flag: { svg: jaFlag, viewBox: "0 -77 512 512" },
  },
];

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    // lng: "en-US",
    fallbackLng: "en",
    resources: {
      en: {
        translation: en,
      },
      ja: {
        translation: ja,
      },
    },
  });

export default i18n;
