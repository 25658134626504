import React, { useState } from "react";
import { Navbar, Container, Image } from "react-bootstrap";
import logo from "assets/images/himegami1_haikei.png";
import { NavLink } from "react-router-dom";
import Account from "components/Account";

import { useTranslation } from "react-i18next";
import { languagesLabel } from "common/i18n";
import {
  Hidden,
  Menu,
  MenuItem,
  SvgIcon,
  Button,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

function MainNavbar() {
  const [t, i18n] = useTranslation();

  const [languageMenu, setLanguageMenu] = useState(null);

  const handleLanguageIconClick = (event) => {
    setLanguageMenu(event.currentTarget);
  };
  const handleLanguageMenuClose = (e) => {
    setLanguageMenu(null);
  };
  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguageMenu(null);
  };

  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = (e) => {
  //   setAnchorEl(null);
  // };

  return (
    <Navbar
      className="navbar navbar-default navbar-default-2"
      collapseOnSelect
      expand="lg"
    >
      <Container>
        <NavLink to="/">
          <Image
            src={logo}
            fluid
            alt="logo"
            style={{
              width: 64,
              height: 64,
            }}
          />
        </NavLink>
        <Hidden mdUp>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <SvgIcon
              className="navbar-right"
              style={{ cursor: "pointer", marginRight: 10 }}
              onClick={handleLanguageIconClick}
              component={
                languagesLabel.find(
                  (language) => language.code === i18n.language
                )?.flag.svg
              }
              viewBox={
                languagesLabel.find(
                  (language) => language.code === i18n.language
                )?.flag.viewBox
              }
            />
          </div>

          <Menu
            id="language-menu"
            anchorEl={languageMenu}
            open={Boolean(languageMenu)}
            onClose={handleLanguageMenuClose}
          >
            {languagesLabel.map((language) => (
              <MenuItem
                key={language.code}
                component="a"
                data-no-link="true"
                selected={i18n.language === language.code}
                onClick={() => handleChangeLanguage(language.code)}
                lang={language.code}
                hrefLang={language.code}
              >
                <SvgIcon
                  onClick={handleLanguageIconClick}
                  component={language.flag.svg}
                  viewBox={language.flag.viewBox}
                />
              </MenuItem>
            ))}
          </Menu>
        </Hidden>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <ul className="nav navbar-nav navbar-right ml-auto">
            <li className="nav-item">
              <NavLink
                exact
                activeClassName="nav-item-active"
                className="nav-link-custom"
                to="/"
              >
                {t("nav.home")}
              </NavLink>
            </li>
            <li className="nav-item">
              <div className="dropdown">
                <Button className="dropbtn" style={{ paddingBottom: 10 }}>
                  <Typography style={{ fontSize: 18, fontWeight: 500 }}>
                    Learn
                  </Typography>{" "}
                  <ArrowDropDownIcon />
                </Button>
                <div className="dropdown-content">
                  <NavLink className="learn-item" to="/basics">
                    How It Works
                  </NavLink>
                  <NavLink className="learn-item" to="/technology">
                    Technology
                  </NavLink>
                  <NavLink className="learn-item" to="/how-OMK-work">
                    How OMK Works
                  </NavLink>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="nav-item-active"
                className="nav-link-custom"
                to="/exchange"
              >
                {t("nav.exchange")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="nav-item-active"
                className="nav-link-custom"
                to="/stake"
              >
                {t("nav.stake")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="nav-item-active"
                className="nav-link-custom"
                to="/dashboard"
              >
                {t("nav.dashboard")}
              </NavLink>
            </li>

            <Hidden smDown>
              <li className="nav-item">
                <SvgIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleLanguageIconClick}
                  component={
                    languagesLabel.find(
                      (language) => language.code === i18n.language
                    )?.flag.svg
                  }
                  viewBox={
                    languagesLabel.find(
                      (language) => language.code === i18n.language
                    )?.flag.viewBox
                  }
                />

                <Menu
                  id="language-menu"
                  anchorEl={languageMenu}
                  open={Boolean(languageMenu)}
                  onClose={handleLanguageMenuClose}
                >
                  {languagesLabel.map((language) => (
                    <MenuItem
                      key={language.code}
                      component="a"
                      data-no-link="true"
                      selected={i18n.language === language.code}
                      onClick={() => handleChangeLanguage(language.code)}
                      lang={language.code}
                      hrefLang={language.code}
                    >
                      <SvgIcon
                        onClick={handleLanguageIconClick}
                        component={language.flag.svg}
                        viewBox={language.flag.viewBox}
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </li>
            </Hidden>

            <li className="nav-item sign-in-btn d-flex">
              <Account />
            </li>
          </ul>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default MainNavbar;
