import React from "react";
import Lottie from "react-lottie";
import animationData from "assets/images/lottie/coin-loading.json";

export default function Spinner({ width, height, style, ...rest }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    // rendererSettings: {
    //   preserveAspectRatio: 'xMidYMid slice',
    // },
  };

  return (
    <Lottie
      options={{ ...defaultOptions, ...rest }}
      width={width ? width : 150}
      height={height ? height : 150}
      style={style}
    />
  );
}
